<!--
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 22:54:53
-->
<template>
  <a-layout-sider
    class="theme-side-color"
    v-model:collapsed="collapsed"
    :trigger="null"
    collapsible
  >
    <div class="logo">
      <a-avatar :size="50" shape="square"> {{ logo }} </a-avatar>
    </div>
    <a-menu
      class="theme-side-color"
      mode="inline"
      theme="light"
      v-model:selectedKeys="selectedKeys"
      v-model:openKeys="openKeys"
      @click="onOpenChange"
    >
      <template v-for="menu in MENUARR" :key="menu.key">
        <a-sub-menu v-if="menu.subMenu" :key="menu.key">
          <template #title>
            <div @click="router.push({ name: menu.pathName })">
              <Icon v-if="menu.icon" :icon="menu.icon" />
              <span>{{ menu.title }}</span>
              <!--<WLink :to="{ name: menu.pathName }" tag="span">{{ menu.title }}</WLink>-->
            </div>
          </template>
          <a-menu-item v-for="subMenu in menu.subMenu" :key="subMenu.key">
            <div @click="router.push({ name: subMenu.pathName })">
              <Icon v-if="subMenu.icon" :icon="subMenu.icon" />
              <span>{{ subMenu.title }}</span>
              <!--<WLink :to="{ name: subMenu.pathName }" tag="span">{{ subMenu.title }}</WLink>-->
            </div>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item v-else :key="menu.key">
          <div @click="router.push({ name: menu.pathName })">
            <Icon v-if="menu.icon" :icon="menu.icon" />
            <span>{{ menu.title }}</span>
          </div>
        </a-menu-item>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script  >
import { defineComponent, inject } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { collapsed } from "../useLayoutStore";
import { selectedKeys, openKeys, onOpenChange } from "./useSider";
import { menuArr } from "@/route/menuHandle.js";
import { getValue } from "@/common/util/storage";
import { Icon } from "@/components/Wicon/Wicon";
import { setPageTabs } from "@/composition/usePageTabs";

export default defineComponent({
  components: { Icon },
  setup() {
    const { field,logo } = inject("globalConfig");
    const adminInfo = getValue(field.info);
    const router = useRouter();
    // 路由信息
    const route = useRoute();
    selectedKeys.value = [route.name];
    openKeys.value = route.meta.parent ? [route.meta.parent] : [];

    // 路由监听
    onBeforeRouteUpdate((to) => {
      // console.log('路由信息',to)
      setPageTabs({ title: to.meta.breadcrumbName, name: to.name });
      selectedKeys.value = [to.name];
      openKeys.value = to.meta.parent ? [to.meta.parent] : [];
    });

    const MENUARR = menuArr.value.filter((menu) => {
      if (!menu.useType) return true;
      if (menu.useType.length) {
        if (~menu.useType.indexOf(getValue(field.type))) {
          return true;
        } else {
          return false;
        }
      }
    });

    return {
      logo,
      router,
      MENUARR,
      collapsed,
      selectedKeys,
      openKeys,
      adminInfo,
      onOpenChange,
    };
  },
});
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 40px 10px;
}
</style>
