/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-04-04 16:33:47
 */

import { ref, readonly } from "vue";

let _collapsed = ref(false);

export const collapsed = readonly(_collapsed);

export const changeCollapsed = () => {
  _collapsed.value = !_collapsed.value;
};
