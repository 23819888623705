/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 02:40:00
 */

import { ref } from "vue";


// 数据
export const selectedKeys = ref(["DataManage"]);
export const openKeys = ref([]);

// 选择item事件
export const onOpenChange = ({ keyPath }) => {
  if (keyPath.length === 1) {
    openKeys.value = [];
  } else {
    openKeys.value = [keyPath[keyPath.length - 1]];
  }
};
