/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-19 21:24:01
 */

import { ref, readonly } from "vue";
import path from "./path/indexChildren";
// import { accessRights } from "@/common/config/menu.config";

let _menuArr = ref([]);
let _rootMenuKeys = ref([]);

export const menuArr = readonly(_menuArr);
export const rootMenuKeys = readonly(_rootMenuKeys);

// 判斷路由權限

// 菜单处理
_menuArr.value = path
  .filter((item) => !item.meta.parent ) // 将下级路由全部剔除
  .filter((item) => !item.meta.hidden ) // 将下级路由全部剔除
  .map((item) => handle(item)); // 只处理单个和父级路由

function handle({ name, meta = {} }) {
  // 拼装基本数据
  let menuItem = {
    title: meta.breadcrumbName || "",
    key: name,
    ...meta,
  };

  // 添加下级路由
  if (meta.subElement) {
    // 记录父级菜单的name值
    _rootMenuKeys.value.push(name);
    // 得到下级路由
    const subMenu = path.filter((value) => {
      return meta.subElement.includes(value.name);
    });
    // 添加数据
    menuItem.subMenu = subMenu.map((item) => handle(item));
  } else {
    // 单个路由
    menuItem.pathName = name;
  }

  return menuItem;
}
